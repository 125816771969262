/* sm - md  */
@media (max-width: 1280px) {
  .container {
    /* dashed border */
    border: 2px dashed #000;
    border-radius: 20px;
    overflow: hidden;
  }
}
/* xl  */
/* lg  */
@media (min-width: 1280px) {
  .container {
    width: 457px;
    height: 221px;
    /* dashed border */
    background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='30' ry='30' stroke='%23222222FF' stroke-width='1' stroke-dasharray='10%2c10' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e");
    border-radius: 30px;
    overflow: hidden;
  }
}
/* xl  */
@media (min-width: 1920px) {
  .container {
    width: 609px;
    height: 294px;
    /* dashed border */
    background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='30' ry='30' stroke='%23222222FF' stroke-width='1' stroke-dasharray='10%2c10' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e");
    border-radius: 30px;
    overflow: hidden;
  }
}
