@font-face {
  font-family: 'YoungSerif';
  src: url('./fonts/YoungSerif/YoungSerif-Regular.ttf') format('truetype'),
    url('./fonts/YoungSerif/YoungSerif-Regular.otf') format('open-type'),
    url('./fonts/YoungSerif/YoungSerif-Regular.woff2') format('woff2');
}

html,
body,
#root {
  height: 100%;
  height: 100vh;
}
