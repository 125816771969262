@font-face {
  font-family: "YoungSerif";
  src: url("../fonts/YoungSerif/YoungSerif-Regular.otf") format("open-type"),
    url("../fonts/YoungSerif/YoungSerif-Regular.woff2") format("woff2");
}

body{
  margin: 0;
  color: #222222;
  font-size: 18px;
  line-height: 1.5;
  overflow-x: hidden;
  font-family: "Poppins", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: Poppins, sans-serif;
  -webkit-tap-highlight-color: transparent;
}

h1{
  color: #222222;
  font-size: 62px;
  font-weight: 500;
  line-height: 0.97;
  font-family: 'YoungSerif', sans-serif;
}
h2{
  color: #222222;
  font-size: 32px;
  font-weight: 500;
  line-height: 1;
  letter-spacing: -1px;
}

@media (min-width: 900px){
  h2{
    font-size: 36px;
  }
}